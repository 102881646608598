export default {
  name: 'EntryPhoneNumber',
  props: {
    phoneNumberInvalid: Boolean,
    messageErrors: String,
    disableInputPhoneNumber: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      inputPhoneNumber: '',
      maskPhoneNumber: '00-0000-0000-00000',
    };
  },
  watch: {
    // [Emit] set input phone number value
    inputPhoneNumber(value) {
      // Set input phone number
      this.$emit('set-input-phone-number', value);
    },
  },
};
