import { mapGetters } from 'vuex';
import { IMAGES } from '@assets';

export default {
  name: "AppHeader",
  computed: {
    ...mapGetters({
      headerLogoUrl: 'headerLogoUrl',
    })
  },
  methods: {
    // Get header logo url
    fullHeaderLogoUrl() {
      return this.headerLogoUrl ? this.headerLogoUrl : IMAGES.giftee_logo;
    }
  },
}
