import {
  HTTP_CODE_OK,
} from '@constants'
import store from '@/store'

export default async function checkLotteryCampaign({ to, next }) {
  try {
    const state = store.state;

    // Check if have lottery campaign setting data will fetch data
    if (!state.campaignSetting && !state.loseContentSetting) {
      // [STORE] dispatch load lottery campaign setting
      const result = await store.dispatch('loadLotteryCampaignSetting', String(to.params.lotteryUuid));

      if (result && result?.status && result?.data) {
        if (result?.data?.survey_link) {
          // Start page loading
          store.dispatch('startPageRedirectLoading');

          // Redirect to survey link
          return window.location.href = result?.data?.survey_link;
        }

        // Status is not 200 -> show error
        if (result.status !== HTTP_CODE_OK) {
          return await next({
            name: 'campaign.error.404'
          });
        }
      }
    }

    // Next guard router
    return await next();
  } catch (error) {
    return await next({
      name: 'campaign.error'
    });
  }
}
