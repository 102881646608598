import {
  checkCampaign,
  followUtmQuery,
  checkCampaignType,
  checkLotteryCampaign
} from '@middleware';

const GLOBAL_MIDDLEWARE = [
  checkCampaign, 
  followUtmQuery,
  checkCampaignType
];

export const routes = [
  {
    path: '/questionnaires/callback',
    name: 'questionnaires.callback',
    component: () => import('@views/GifteeCP/Campaign/Containers/Questionnaires')
  },
  {
    path: '/:campaignUrlName/:campaignUrl',
    component: () => import('@views/GifteeCP'),
    children: [
      // Top page
      {
        path: '/',
        name: 'campaign.home',
        component: () => import('@views/GifteeCP/Campaign/Containers/Home'),
        meta: {
          middleware: [checkCampaign, followUtmQuery],
        }
      },
      // Entry page
      {
        path: 'auth',
        name: 'campaign.auth.serial',
        component: () => import('@views/GifteeCP/Campaign/Containers/SerialAuth'),
        meta: {
          middleware: GLOBAL_MIDDLEWARE,
        }
      },
      {
        path: 'verify-otp',
        name: 'campaign.auth.verifyotp',
        component: () => import('@views/GifteeCP/Campaign/Containers/VerifyOtpAuth'),
        meta: {
          middleware: GLOBAL_MIDDLEWARE,
        }
      }
    ]
  },
  {
    path: '/:campaignUrlName/lottery/:lotteryUuid',
    component: () => import('@views/GifteeCP'),
    children: [
      {
        path: '/',
        name: 'lottery.game',
        component: () => import('@views/GifteeCP/Campaign/Containers/Lottery'),
        meta: {
          middleware: [checkLotteryCampaign],
        }
      },
    ]
  },
  {
    path: '/error',
    name: 'campaign.error',
    component: () => import('@views/Errors/Containers/CampaignError'),
    props: true,
  },
  {
    path: '/error-404',
    alias: '*',
    name: 'campaign.error.404',
    component: () => import('@views/Errors/Containers/Error404')
  }
]
