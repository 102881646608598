import { mapState } from 'vuex';
import { otpAuthService } from '@services';
import { DEFAULT_OTP_COUNTDOWN } from '@constants';
import { ButtonDefault } from "@components";

export default {
  name: 'EntryVerifyOtp',
  components: { ButtonDefault },
  data() {
    return {
      inputOtpCode: '',
      maskOtpCode: '000000',
      countDown: DEFAULT_OTP_COUNTDOWN, // Count down time
      enableV2: false,
      digitCount: 6,
      digits: [],
      otpInvalid: false,
      messageErrors: '',
      isProcessing: false
    };
  },
  watch: {
    // [EMIT] Set state for input, if have inputted value is valid
    inputOtpCode(value) {
      // Check End-user enter phone number is it valid or not to show submit button
      if (value) {
        this.$emit('set-valid-data-input', true);
      } else {
        this.$emit('set-valid-data-input', false);
      }
    },
  },
  methods: {
    // Event when input otp number
    handleKeyDown: function (event, index) {
      // Reset input otp state
      this.otpInvalid = false;

      if (event.key !== "Tab" &&
          event.key !== "ArrowRight" &&
          event.key !== "ArrowLeft"
      ) {
        event.preventDefault();
      }

      if (event.key === "Backspace") {
        this.$set(this.digits, index, null);
        if (index != 0) {
          (this.$refs.otpInputs.children)[index-1].focus();
        }
      }

      if ((new RegExp('^([0-9])$')).test(event.key)) {
        this.$set(this.digits, index, event.key);

        // Auto focus to next input
        if (index != this.digitCount - 1) {
          (this.$refs.otpInputs.children)[index + 1].focus();
        }
      }

      // Check is digits full
      if (this.isDigitsFull) {
        // Call verify Otp
        this.onHandleVerifyOtp();
      }
    },
    // Back to input phone number
    backToInputPhoneNumber() {
      this.$emit('canceled');
    },
    // On handle verify otp
    async onHandleVerifyOtp() {
      this.isProcessing = true;

      // Prepare payload to verify OTP
      const payload = {
        campaign_url: this.campaignUrl,
        campaign_url_name: this.campaignUrlName,
        lottery_url: this.lotteryCampaign.url,
        channel_id: this.entryPhoneNumber.channelId,
        phone_number: this.entryPhoneNumber.phoneNumber,
      }

      // Get otp code
      const otpCode = this.digits.join('');

      // Call verify Otp
      const result = await otpAuthService.verifyOtp(payload, otpCode);

      if (result.success) {
        const lotteryUrl = result.data?.lottery_url;

        const lotteryUuid = lotteryUrl.substring(
          lotteryUrl.lastIndexOf('/') + 1
        );

        if (result?.data?.survey_link) {
          // Start page loading
          this.$store.dispatch('startPageRedirectLoading');

          // Redirect to survey link
          return window.location.href = result.data.survey_link;
        }

        // Move to lottery game
        this.$router
          .push({
            name: 'lottery.game',
            params: { lotteryUuid },
            replace: true,
          })
          .catch(() => {});
      } else {
        if (this.$refs.inputOtpCode) {
          this.$refs.inputOtpCode[0].focus();
        }

        this.otpInvalid = true;
        this.messageErrors = this.$t(result.error_message);
      }

      this.isProcessing = false;
    },
    // On handle send otp
    async onHandleResendOtp() {
      this.isProcessing = true;

      // Prepare payload
      const payload = {
        campaign_url: this.campaignUrl,
        campaign_url_name: this.campaignUrlName,
        channel_id: this.entryPhoneNumber.channelId,
        phone_number: this.entryPhoneNumber.phoneNumber,
        full_phone_number: this.$helpers.addPlusSignToPhoneNumber(this.entryPhoneNumber.fullPhoneNumber),
        resend_otp: true,
      };

      // Call send otp
      const result = await otpAuthService.sendOtp(payload);

      if (result.success) {
        // Reset countdown time
        this.countDown = DEFAULT_OTP_COUNTDOWN;
      } else {
        this.otpInvalid = true;
        this.messageErrors = this.$t(result.error_message);
      }

      this.isProcessing = false;
    },
  },
  computed: {
    // Format time for countdown
    countDownFormat: function () {
      var date = new Date(0);
      date.setSeconds(this.countDown); // Specify value for SECONDS here

      return date.toISOString().substring(11, 19);
    },
    // Check if input digits full for OTP
    isDigitsFull: function () {
      for (const elem of this.digits) {
        if (elem == null || elem == undefined) {
          return false;
        }
      }

      return true;
    },
    ...mapState({
      campaignUrl: 'campaignUrl',
      campaignUrlName: 'campaignUrlName',
      lotteryCampaign: 'lotteryCampaign',
      entryPhoneNumber: 'entryPhoneNumber',
    })
  },
  mounted() {
    // Start count down time for resend Otp
    setInterval(() => {
      if (this.countDown > 0) {
        this.countDown--;
      }
    }, 1000);
  },
  created(){
    // Set default value for digits
    for (let i = 0; i < this.digitCount; i++) {
      this.digits[i] = null;
    }
  }
};
