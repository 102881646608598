export default {
  name: 'MainVisualContent',
  props: {
    mainVisualImageUrl: String,
    mainText: String,
    mainSubText: String,
    itemBrandName: String,
    itemName: String,
  },
};
