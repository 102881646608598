import {
  CAMPAIGN_IS_NOT_EXPIRED,
  OTP_CHANNEL_SMS,
  OTP_CHANNEL_ZALO,
  ZNS_AVAILABLE_COUNTRY_CODES
} from '@constants';
import ButtonDefault from "@components/ButtonDefault";
import { mapState } from 'vuex';

export default {
  name: 'AgreementContent',
  components: { ButtonDefault },
  data() {
    return {
      isCheckedAgreement: false,
      otpChannelSms: OTP_CHANNEL_SMS,
      otpChannelZalo: OTP_CHANNEL_ZALO,
      sendViaType: null,
    };
  },
  computed: {
    ...mapState({
      country: 'country'
    })
  },
  props: {
    skipAgreementFlg: Boolean|Number,
    agreementText: String,
    isExpired: {
      type: Number,
      required: true,
    },
    buttonText: String,
    hasInputValue: {
      type: Boolean,
      default: false,
    },
    isProcessing: {
      type: Boolean,
      default: false,
    },
    requireInputValue: {
      type: Boolean,
      default: false,
    },
    isEntryTypePhoneNumber: {
      type: Boolean,
      default: false
    },
  },
  methods: {
    // Check campaign not expired
    isNotExpired() {
      return this.isExpired == CAMPAIGN_IS_NOT_EXPIRED;
    },
    // Check is disable button submit
    isDisableButtonSubmit() {
      if (this.isProcessing) return true;

      if (!this.skipAgreementFlg) {
        return !this.isCheckedAgreement || (this.requireInputValue && !this.hasInputValue);
      }
    
      return this.requireInputValue && !this.hasInputValue;
    },
    // Handle submit
    async onSubmit(sendViaType = null) {
      if (this.isEntryTypePhoneNumber) {
        this.sendViaType = sendViaType;

        return await this.$emit('handle-submit', sendViaType);
      }

      return await this.$emit('handle-submit');
    },
    // Check is loading button
    isButtonProcessing(sendViaType = null) {
      if (this.isEntryTypePhoneNumber) {
        return this.isProcessing && this.sendViaType === sendViaType;
      }

      return this.isProcessing;
    },
    // Check zns available in country
    isZnsAvailable() {
      return ZNS_AVAILABLE_COUNTRY_CODES.includes(this.country?.country_code);
    }
  },
};
