import Vue from 'vue';
import Vuex from 'vuex';

import {
    HTTP_CODE_OK,
    TOP_PAGE_AGREEMENT_TERM_KEY
} from '@constants'

// Service
import { campaignService } from '@services'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        // Campaign Data
        campaignUrl: null,
        campaignUrlName: null,
        campaignInfo: null,
        campaignSetting: null,
        country: null,

        // Page setting
        topContentSetting: null,
        entryContentSetting: null,
        loseContentSetting: null,
        receiveContentSetting: null,

        // Utm data
        utmCampaign: '',
        utmMedium: '',
        utmSource: '',

        // Serial code via SMS
        serialCodeViaSMS: null,

        // Lottery campaign
        lotteryCampaign: {
            url: null,
            winFlg: null,
        },

        // Page loading status
        isPageLoading: false,
        isPageRedirectLoading: false,

        // Top page agreement term status
        topPageAgreementTermStatus: sessionStorage.getItem(TOP_PAGE_AGREEMENT_TERM_KEY) || false,

        // Entry phone number data inputted
        entryPhoneNumber: {
            channelId: null,
            phoneNumber: null,
            fullPhoneNumber: null,
        },
    },
    mutations: {
        setCampaign: function (state, {
            campaignUrl,
            campaignUrlName,
            campaignInfo,
            campaignSetting,
            country
        }) {
            state.campaignUrl = campaignUrl;
            state.campaignUrlName = campaignUrlName;
            state.campaignInfo = campaignInfo;
            state.campaignSetting = campaignSetting;
            state.country = country;
        },
        setTopContentSetting: function (state, topContentSetting) {
            state.topContentSetting = topContentSetting;
        },
        setEntryContentSetting: function (state, entryContentSetting) {
            state.entryContentSetting = entryContentSetting;
        },
        setLoseContentSetting: function (state, loseContentSetting) {
            state.loseContentSetting = loseContentSetting;
        },
        setReceiveContentSetting: function (state, receiveContentSetting) {
            state.receiveContentSetting = receiveContentSetting;
        },
        setUtm: function (state, {
            utmCampaign,
            utmMedium,
            utmSource
        }) {
            state.utmCampaign = utmCampaign;
            state.utmMedium = utmMedium;
            state.utmSource = utmSource;
        },
        // Set campaign url data
        setCampaignUrlData: function (state, {
            campaignUrl,
            campaignUrlName,
        }) {
            state.campaignUrl = campaignUrl;
            state.campaignUrlName = campaignUrlName;
        },
        setSerialCodeViaSMS: function (state, serialCode) {
            state.serialCodeViaSMS = serialCode;
        },
        setLotteryCampaignWinFlg: function (state, flg) {
            state.lotteryCampaign.winFlg = flg;
        },
        // Set lottery campaign url
        setLotteryCampaignUrl: function (state, url) {
            state.lotteryCampaign.url = url;
        },
        // Set campaign setting
        setCampaignSetting: function (state, {campaignSetting}) {
            state.campaignSetting = campaignSetting;
        },
        // Set campaign info
        setCampaignInfo: function (state, {campaignInfo}) {
            state.campaignInfo = campaignInfo;
        },
        // Set page loading status
        setPageLoading(state, status) {
            state.isPageLoading = status;
        },
        // Set page redirect loading status
        setPageRedirectLoading(state, status) {
            state.isPageRedirectLoading = status;
        },
        // Set top page agreement term status
        setTopPageAgreementTermState(state, status) {
            // Set state
            state.topPageAgreementTermStatus = status;
            // Set session storage
            sessionStorage.setItem(TOP_PAGE_AGREEMENT_TERM_KEY, status);
        },
        // Set entry phone number data inputted
        setEntryPhoneNumber: function (state, {
            channelId,
            phoneNumber,
            fullPhoneNumber
        }) {
            state.entryPhoneNumber.channelId = channelId;
            state.entryPhoneNumber.phoneNumber = phoneNumber;
            state.entryPhoneNumber.fullPhoneNumber = fullPhoneNumber;
        },
    },
    actions: {
        // [API] load campaign detail by campaign_url & campaign_url_name
        loadCampaign: async function ({ commit }, {
            campaignUrlName, 
            campaignUrl,
        }) {
            // Fetch data
            const response = await campaignService.index(campaignUrlName, campaignUrl);

            // Save data to state if fetch data success
            if (response.status == HTTP_CODE_OK) {
                const data = response.data;
                const campaignSetting = data.campaign_setting;

                commit('setCampaign', {
                    campaignUrl: campaignUrl,
                    campaignUrlName: campaignUrlName,
                    campaignInfo: data.campaign_info,
                    campaignSetting: data.campaign_setting,
                    country: data.country,
                });

                // Set topContentSetting
                if (!campaignSetting?.skip_top_flg && data?.top_content_setting) {
                    commit('setTopContentSetting', data.top_content_setting);
                }

                // Set entryContentSetting
                if(data?.entry_content_setting) commit('setEntryContentSetting', data.entry_content_setting);

                // Set loseContentSetting
                if(data?.lose_content_setting) commit('setLoseContentSetting', data.lose_content_setting);

                // Set receiveContentSetting
                if (!campaignSetting?.skip_receive_flg && data?.receive_content_setting) {
                    commit('setReceiveContentSetting', data.receive_content_setting);
                }
            }

            return response;
        },
        // Set Utm data (get from route query)
        setUtmData: function ({ commit }, utmObj) {
            commit('setUtm', utmObj);
        },
        // Set campaign url + campaign url name data (get from route query)
        setCampaignUrlData: function ({ commit }, campaignUrlData) {
            commit('setCampaignUrlData', campaignUrlData);
        },
        // Set campaign url + campaign url name data (get from route query)
        setSerialCodeViaSMS: function ({ commit }, serialCode) {
            commit('setSerialCodeViaSMS', serialCode);
        },
        // Set lottery campaign win flg data
        setLotteryCampaignWinFlg: function ({ commit }, flg) {
            commit('setLotteryCampaignWinFlg', flg);
        },
        // Load lottery campaign setting data
        loadLotteryCampaignSetting: async function ({ commit }, lotteryUuid) {
            // Fetch data
            const response = await campaignService.lotteryCampaignSetting(lotteryUuid);

            // Save data to state if fetch data success
            if (response.status == HTTP_CODE_OK) {
                const data = response?.data;
                const campaignSetting = data.campaign_setting;
                const campaignInfo = data.campaign_info;

                // Set campaignSetting
                commit('setCampaignSetting', {
                    campaignSetting,
                });

                // Set setCampaignInfo
                commit('setCampaignInfo', {
                    campaignInfo,
                });

                // Set loseContentSetting
                if(data?.lose_content_setting) commit('setLoseContentSetting', data.lose_content_setting);

                // Set receiveContentSetting
                if (!campaignSetting?.skip_receive_flg && data?.receive_content_setting) {
                    commit('setReceiveContentSetting', data.receive_content_setting);
                }
            }

            return response;
        },
        // Set start page loading
        startPageLoading({ commit }) {
            commit('setPageLoading', true);
        },
        // Set stop page loading
        stopPageLoading({ commit }) {
            commit('setPageLoading', false);
        },
        // Set start page redirect loading
        startPageRedirectLoading({ commit }) {
            commit('setPageRedirectLoading', true);
        },
        // Set start page redirect loading
        stopPageRedirectLoading({ commit }) {
            commit('setPageRedirectLoading', false);
        },
    },
    getters: {
        // Get full path image to header logo
        headerLogoUrl: state => state.campaignSetting?.header_logo_image_url_s3,
        // Get utm data
        utmData(state) {
            const utmData = {};

            if (state.utmCampaign) utmData.utm_campaign = state.utmCampaign;
            if (state.utmMedium) utmData.utm_medium = state.utmMedium;
            if (state.utmSource) utmData.utm_source = state.utmSource;

            return utmData;
        },
        // Get data campaign after lottery
        lotteryCampaign(state) {
            return {
                url: state.lotteryCampaign.url,
                winFlg: state.lotteryCampaign.winFlg,
            }
        },
        // Get data campaign info
        campaignInfo(state) {
            return {
                campaign_type: state.campaignInfo?.campaign_type,
                campaign_flow: state.campaignInfo?.campaign_flow,
                is_expired: state.campaignInfo?.is_expired,
                is_redemption_campaign: state.campaignInfo?.is_redemption_campaign,
            }
        }
    }
})
