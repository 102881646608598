import store from '@/store';
import {
  CAMPAIGN_IS_EXPIRED,
  SERIAL_CODE_DEFAULT,
  CAMPAIGN_SERIAL_TYPE,
  ROUTER_MAPS,
  SKIP_AGREEMENT_INACTIVE_FLG
} from '@constants';

export default async function checkCampaignType({ to, next, router }) {
  // Check if not agreement term at top page will redirect to top page
  if (
    store.state.topContentSetting?.skip_agreement_flg == SKIP_AGREEMENT_INACTIVE_FLG &&
    !store.state.topPageAgreementTermStatus
  ) {
    return await router
    .push({
      name: 'campaign.home',
      params: {
        campaignUrlName: store.state.campaignUrlName,
        campaignUrl: store.state.campaignUrl,
      },
      replace: true,
    })
    .catch(() => {});
  }

  // Check router path matches with campaign type
  const campaignInfo = store.state.campaignInfo;

  if (campaignInfo?.is_expired === CAMPAIGN_IS_EXPIRED) {
    return await router
      .push({
        name: 'campaign.home',
        params: {
          campaignUrlName: store.state.campaignUrlName,
          campaignUrl: store.state.campaignUrl,
        },
        replace: true,
      })
      .catch(() => {});
  }

  // Get the type from the route params or query
  const campaignType = campaignInfo?.campaign_type;
  let newQuery = {};

  // Check if have utm will append to query
  const { utm_campaign, utm_medium, utm_source } = store.getters.utmData;
  if (utm_campaign) newQuery.utm_campaign = utm_campaign;
  if (utm_medium) newQuery.utm_medium = utm_medium;
  if (utm_source) newQuery.utm_source = utm_source;

  // Check if the current route name matches the expected route name for the type
  if (ROUTER_MAPS[campaignType] && to.name !== ROUTER_MAPS[campaignType]) {
    if (
      to.query?.code && campaignType == CAMPAIGN_SERIAL_TYPE
    ) {
      const serialCode = to.query?.code ? to.query.code : SERIAL_CODE_DEFAULT;

      // Add serial code to query
      if (serialCode) newQuery.code = serialCode;

      return await router.push({
        name: ROUTER_MAPS[CAMPAIGN_SERIAL_TYPE],
        params: {
          campaignUrlName: store.state.campaignUrlName,
          campaignUrl: store.state.campaignUrl,
        },
        query: newQuery,
        replace: true,
      });
    } else {
      return await router.push({
        name: ROUTER_MAPS[campaignType],
        params: {
          campaignUrlName: store.state.campaignUrlName,
          campaignUrl: store.state.campaignUrl,
        },
        query: newQuery,
        replace: true,
      });
    }
  }

  next();
}
